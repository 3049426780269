const productCategory = [
    { id : 1, label : "Pattu", value : "Pattu"},
    { id : 2, label : "Georgette", value : "Georgette"},
    { id : 3, label : "Warm Silk", value : "Warm_Silk"},
    { id : 4, label : "Kathan", value : "Kathan"},
    { id : 5, label : "Ikat", value : "Ikat"},
    { id : 6, label : "Chiffon", value : "Chiffon"},
    { id : 7, label : "Fancy Print", value : "Fancy_Print"},
    { id : 8, label : "Viscose", value : "Viscose"},
    { id : 9, label : "Chiniy Silk", value : "Chiniy_Silk"},
    { id : 10, label : "Tissue Kota", value : "Tissue_Kota"},
    
]


export default productCategory