import React from "react";

const Logo = ({ w, h }) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 370.16679528778167 155.08501865265873"
      className="css-1j8o68f"
    >
      <defs id="SvgjsDefs1029"></defs>
      <g
        transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1661 1689 c-64 -12 -123 -51 -167 -111 -22 -29 -130 -180 -239 -335
-109 -156 -201 -283 -204 -283 -3 0 -87 116 -186 257 -273 388 -286 405 -345
437 -138 75 -312 20 -382 -122 -22 -44 -23 -58 -23 -237 0 -177 2 -193 22
-231 48 -89 150 -144 246 -131 47 7 67 28 67 72 0 41 -27 65 -73 65 -76 0
-122 41 -120 108 1 27 9 43 32 64 68 60 110 34 237 -145 55 -78 148 -211 208
-294 116 -165 163 -213 227 -232 61 -18 148 -15 199 7 64 28 108 79 271 312
220 315 249 354 272 367 72 40 160 -25 142 -105 -10 -48 -47 -75 -110 -80 -42
-3 -59 -10 -70 -25 -21 -30 -18 -71 6 -93 30 -27 130 -25 185 4 52 27 111 94
124 141 17 61 13 360 -6 414 -42 123 -181 202 -313 176z m-1217 -154 c39 -18
14 13 306 -400 127 -182 241 -338 252 -347 25 -22 66 -23 95 -3 12 8 132 171
266 362 135 192 256 359 270 372 34 31 102 39 146 17 37 -20 71 -70 71 -107 0
-25 0 -25 -82 -25 -67 1 -91 -3 -124 -21 -54 -29 -71 -50 -259 -318 -244 -346
-245 -348 -289 -363 -52 -17 -106 -1 -143 41 -15 18 -117 159 -226 314 -138
196 -210 289 -237 307 -50 33 -106 47 -177 44 l-58 -3 3 30 c4 40 26 72 65 95
39 24 78 25 121 5z"
        />
        <path
          d="M470 278 c1 -7 17 -53 38 -103 50 -120 61 -120 113 4 26 64 36 98 29
105 -14 14 -24 1 -52 -68 -13 -33 -28 -61 -33 -63 -6 -1 -23 29 -38 67 -17 41
-35 70 -43 70 -8 0 -14 -6 -14 -12z"
        />
        <path
          d="M792 193 c-28 -67 -37 -101 -30 -108 7 -7 15 -3 25 13 22 32 104 32
126 -1 10 -15 18 -19 25 -12 16 15 -65 205 -88 205 -13 0 -29 -27 -58 -97z
m80 -13 c2 -17 -3 -25 -16 -28 -28 -6 -37 10 -24 46 10 26 15 29 25 19 7 -6
14 -23 15 -37z"
        />
        <path
          d="M1086 274 c-9 -24 -7 -167 3 -183 17 -26 31 1 31 60 0 32 3 59 8 59
4 -1 19 -19 34 -40 15 -22 32 -40 37 -40 4 0 21 19 37 43 l29 42 5 -65 c4 -49
9 -65 20 -65 12 0 15 18 15 100 0 122 -14 134 -65 55 -18 -27 -37 -50 -44 -50
-6 0 -26 23 -44 50 -33 51 -55 62 -66 34z"
        />
        <path
          d="M1494 214 c-44 -97 -53 -134 -31 -134 9 0 19 9 22 20 9 29 99 29 115
0 12 -22 40 -27 40 -7 -1 29 -80 192 -94 195 -12 3 -26 -18 -52 -74z m72 -50
c-6 -15 -41 -19 -50 -6 -2 4 2 23 11 42 l14 35 15 -28 c9 -16 13 -35 10 -43z"
        />
      </g>
    </svg>
  );
};

export default Logo;
